import React, { useState } from "react"
import { Link, useStaticQuery, graphql } from "gatsby"
import './style.scss'
import { QuestionCircleOutlined, AddDependentInput, Button, Checkbox, Container, MasterPage, QuotesForm, TextInput, Select, WomanOutlined, ManOutlined, SelectOption, UserAddOutlined, UsergroupAddOutlined, HomeOutlined, EnvironmentOutlined, UserOutlined } from "../../components";
import Fatherhood from "../../assets/fatherhood.svg";
import axios from "axios"

import _ from "lodash";

import {
  SettingOutlined, EditOutlined, EllipsisOutlined
} from "../../components"
import { Avatar, Button as AntdButton, Card, Popover } from "antd"




const IndexPage = (props) => {
  const data = useStaticQuery(graphql`query assets2 {
        site {
          siteMetadata {
            agent {
                id,
                assets {
                    image
                },
                imageSrc,
                name,
                phone,
                whatsapp,
                email,
                website,
                title,
                calendly
            }
          }
        }
      }
    `)



  return (
    <MasterPage data={data}>
      {/* <Helmet>
          <meta viewport="width=device-width, initial-scale=1, minimum-scale=1,maximum-scale=1, shrink-to-fit=no"/>
        </Helmet> */}
      {/* //<SEO title="Home" /> */}
      <Container className="main-container">
        <Container className="headings-container">
          <h1 className="h1 health-insurance"> Health insurance</h1>
          <h1 className="h1 quotes"> Quotes and</h1>
          <h1 className="h1 plans"> Plans</h1>
        </Container>
        <Container className="content-container">
          <Container className="illustration">
            {data.site.siteMetadata?.agent?.imageSrc ? <img src={data.site.siteMetadata?.agent?.imageSrc} /> : <Fatherhood />}
          </Container>
          <Container>
            <QuotesForm />

            <Container className={"disclaimer"}>
              <span>Disclaimer</span>
            These quotes are only estimates. Your actual premium, deductible, and coverage may be different. The quotes offered on this platform are not a promise of insurance, and your eligibility can only be determined by a qualified agent. Only your qualified agent can determine your insurability, plan rates, and health coverage. All quotes are subject to change.
            </Container>
          </Container>
        </Container>
      </Container >

    </MasterPage >
  )
}

export default IndexPage
